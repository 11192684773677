$background: #eff2f6;
$color:#424e79;


$dark-body:#11163a;
$dark-theme:#2d3258;
$dark-theme-light:#1b2352;
$dark-theme1: #202859;
$dark-theme2:#1b2352;
$dark-color:#edf0f5;
$border:1px solid #eceff3;
$dark-color1:#5360d9;


/*Color variables*/
$primary:#29327f; 
$secondary:#f08900;
$success:#38cb89;
$info:#5b7fff;
$warning:#f3a403;
$danger:#ef4b4b;
$blue:#083dc1;
$yellow:#ecb403;
$pink:#ec82ef;
$teal:#2bcbba;
$purple:#aa4cf2;
$orange:#fc7303;
$dark:#343a40;
$indigo:#6574cd;
$white:#fff;
$black:#000;

/*gray variables*/
$gray-100:#f1f2f7;
$gray-200:#e5e7f1;
$gray-300:#d2d6e4;
$gray-400:#b7bdd2;
$gray-500:#a2aac7;
$gray-600:#8d96b7;
$gray-700:#7a85ad;
$gray-800:#53608c;
$gray-900:#424e79;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);


	

